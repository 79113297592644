<template>
  <button
    @click="scrollTo"
    :class="{ 'small-on-mobile': smallOnMobile }"
    class="circle d-flex align-center justify-center"
  >
    <h5 v-if="buttonLabel" class="h5" ref="label" v-html="buttonLabel"></h5>
    <arrow v-else />
  </button>
</template>

<script>
import { gsap } from 'gsap'
import Splitting from 'splitting'

import Arrow from '@/assets/img/cta-arrow.svg'

export default {
  name: 'ButtonScrollTo',
  props: {
    target: {
      type: [String, Number],
      required: true
    },
    label: {
      type: String,
      required: false
    },
    smallOnMobile: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  components: { Arrow },
  data () {
    return {
      buttonLabel: this.label ? Splitting.html({ content: this.label, by: 'chars' }) : null
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.createTimeline()
    })
  },
  methods: {
    scrollTo () {
      gsap.to(window, { duration: 0.5, scrollTo: typeof this.target === 'string' ? `#${this.target}` : window.innerHeight * this.target })
    },
    createTimeline () {
      if (!this.label) {
        return
      }

      this.tl = gsap.timeline({
        repeat: -1,
        repeatDelay: 1,
        scrollTrigger: {
          trigger: this.$refs.label,
          toggleActions: 'play pause resume reset'
        }
      })

      this.tl.fromTo(this.$refs.label.querySelectorAll('.char'), {
        yPercent: 0
      }, {
        duration: 0.4,
        stagger: 0.05,
        yPercent: -200
      })

      this.tl.fromTo(this.$refs.label.querySelectorAll('.char'), {
        yPercent: 200
      }, {
        duration: 0.4,
        stagger: 0.05,
        yPercent: 0,
        immediateRender: false,
        ease: 'power3'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .char,
  .word,
  .words {
    display: inline-block;
    line-height: 1;
    vertical-align: middle;
    overflow: hidden;
  }
}

.circle {
  width: rem(78px);
  height: rem(78px);
  border: rem(1px) solid $color-detail;
  border-radius: 50%;
  text-decoration: none;
  outline: none;

  &.small-on-mobile {
    @include breakpoint ('sm-and-down') {
      width: rem(50px) !important;
      height: rem(50px) !important;
    }
  }

  &:hover {
    svg {
      animation: onHover 0.5s;
    }
  }

  svg {
    width: rem(27px);
    height: rem(27px);
    transform: rotateZ(-45deg);

    @include breakpoint ('sm-and-down') {
      width: rem(17px) !important;
      height: rem(17px) !important;
    }
  }
}

@keyframes onHover {
  0% {
    transform: translate3d(0%, 0%, 0) rotateZ(-45deg);
    opacity: 1;
  }

  33% {
    transform: translate3d(0, -50%, 0) rotateZ(-45deg);
    opacity: 0;
  }

  34% {
    transform: translate3d(0, 50%, 0) rotateZ(-45deg);
    opacity: 0;
  }

  100% {
    transform: translate3d(0%, 0%, 0) rotateZ(-45deg);
    opacity: 1;
  }
}
</style>
